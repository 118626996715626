import React, { useContext, useEffect, useRef } from "react";
import {
  CustomNode,
  CustomEdge,
  AnalyseData,
  AutomationTree,
  NodeStatus,
  EditionData,
} from "../../types/ReactFlowCustomTypes";
import { Report } from "@thiana/api-thiana-client";
import Context from "../../context/Context";
import OpenAI from "openai";

export default function useFlowAutomation() {
  const {
    updateIsLLMProcessing,
    currentReport,
    updateCurrentReport,
    currentAutomationTree,
    updateCurrentAutomationTree,
  } = useContext(Context);

  const updatedGeneration = useRef(currentReport?.generation);
  const currentTranscription = useRef(currentReport?.transcription);

  useEffect(() => {
    // console.log("currentReport?.generation", currentReport?.generation);
    updatedGeneration.current = currentReport?.generation;
    currentTranscription.current = currentReport?.transcription;
  }, [currentReport]);

  const launchPipeline = async (nodes: CustomNode[], edges: CustomEdge[]) => {
    await processNode(
      nodes.find((node: CustomNode) => node.type === "start") as CustomNode,
      nodes,
      edges
    );
  };

  const processNode = async (
    node: CustomNode,
    nodes: CustomNode[],
    edges: CustomEdge[]
  ) => {
    console.log("node to process", node);
    if (!node) return;

    // If the node is of type "end", stop the pipeline
    if (node.type === "end") {
      console.log("Pipeline terminée");
      return;
    }

    // If the node is of type "start", move to the next one
    if (node.type === "start") {
      console.log("Début de la pipeline");
      const nextNode = getNextNode(node, nodes, edges);
      if (nextNode) await processNode(nextNode, nodes, edges);
      return;
    }

    const edge = edges.find((e) => e.target === node.id);
    const precedentNode = nodes.find((n) => n.id === edge?.source);
    const precedentNodeString = precedentNode?.data
      ? (precedentNode.data as AnalyseData | EditionData).output
      : "";

    // Handling other types of nodes
    switch (node.type) {
      case "condition":
        console.log(`Traitement du noeud condition: ${node.id}`);
        const conditionResult = await processConditionNode(
          node,
          "Ceci est un test"
        );
        if (!conditionResult) {
          console.log(`Condition not met for node: ${node.id}`);
        }
        break;
      case "edition":
        console.log(`Traitement du noeud edition: ${node.id}`);

        await processEditionNode(
          node,
          (node.data as EditionData).input_type === "conversation"
            ? currentTranscription.current
            : precedentNodeString,
          currentReport as Report
        );
        break;
      case "analyse":
        // console.log(`Traitement du noeud analyse: ${node.id}`);
        // console.log(
        //   "(node.data as EditionData).input_type",
        //   (node.data as EditionData).input_type
        // );
        // console.log(
        //   "currentTranscription.current",
        //   currentTranscription.current
        // );

        await processAnalyseNode(
          node,
          (node.data as EditionData).input_type === "conversation"
            ? currentTranscription.current
            : precedentNodeString
        );
        break;
      default:
        console.warn(`Type de noeud inconnu: ${node.type}`);
        return;
    }

    // After completing current node processing, move to the next one
    const nextNode = getNextNode(node, nodes, edges);
    if (nextNode) await processNode(nextNode, nodes, edges);
  };

  const getNextNode = (
    currentNode: CustomNode,
    nodes: CustomNode[],
    edges: CustomEdge[]
  ) => {
    // Trouver l'arête correspondant à la source actuelle
    const edge = edges.find((e) => e.source === currentNode.id);
    console.log("edge found", edge);
    if (!edge) return null;

    // Trouver le noeud cible correspondant à l'arête
    const nextNode = nodes.find((n) => n.id === edge.target);
    console.log("nextNode", nextNode);
    return nextNode || null;
  };

  const processConditionNode = async (
    node: CustomNode,
    input_text: string | undefined
  ): Promise<boolean> => {
    if (!node.data || node.type !== "condition") {
      throw new Error(
        "Le noeud doit être de type 'condition' et contenir des données."
      );
    }

    const { instruction } = node.data as AnalyseData;

    // Préparer les données à envoyer
    const payload = {
      input_text,
      instruction,
    };

    console.log(`Connexion au WebSocket pour le noeud ${node.id}`);
    // const mockWebSocket = createMockWebSocket();

    // node.data = {
    //   ...node.data,
    //   status: "processing",
    // } as AnalyseData;

    // const updatedNodes: CustomNode[] = currentAutomationTree?.nodes.map((n) =>
    //   n.id === node.id ? node : n
    // ) as CustomNode[];

    // updateCurrentAutomationTree({
    //   ...currentAutomationTree,
    //   nodes: updatedNodes,
    // } as AutomationTree);

    return new Promise((resolve, reject) => {
      // On ne fait rien avec les messages reçus, ils sont ignorés
      // mockWebSocket.onmessage = (event: any) => {
      //   // Si vous voulez faire quelque chose avec les messages, vous pouvez ajouter un traitement ici,
      //   // mais actuellement, vous ne voulez rien faire de spécifique avec les messages.
      //   const response = event.data;
      //   console.log(`Message reçu pour le noeud ${node.id}: ${response}`);
      // };
      // // On attend seulement la fermeture du WebSocket pour passer à la suite
      // mockWebSocket.onclose = () => {
      //   console.log(`WebSocket fermé pour le noeud ${node.id}`);
      //   // Lorsque le WebSocket se ferme, on résout la promesse pour signaler la fin du traitement de ce noeud
      //   node.data = {
      //     ...node.data,
      //     status: "success",
      //   } as AnalyseData;
      //   resolve(true); // Ou vous pouvez résumer l'état selon la logique de votre application
      // };
      // // Simuler l'envoi des données au WebSocket
      // mockWebSocket.send(JSON.stringify(payload));
    });
  };

  const processEditionNode = async (
    node: CustomNode,
    input_text: string | undefined,
    reportToUpdate: Report
  ): Promise<void> => {
    if (!node.data || node.type !== "edition") {
      throw new Error(
        "Le noeud doit être de type 'edition' et contenir des données."
      );
    }

    console.log(
      "currentGeneration",
      currentReport?.generation.replace(/<[^>]+>/g, "")
    );

    console.log(`Connexion au WebSocket pour le noeud ${node.id}`);

    // Initialisation du noeud avec un output vide et un statut en cours
    node.data = {
      ...node.data,
      output: "",
      status: "processing",
    } as EditionData;
    updateIsLLMProcessing(true);

    const updatedNodes: CustomNode[] = currentAutomationTree?.nodes.map((n) =>
      n.id === node.id ? node : n
    ) as CustomNode[];

    updateCurrentAutomationTree({
      ...currentAutomationTree,
      nodes: updatedNodes,
    } as AutomationTree);

    const updatedNode = { ...node };

    let generation = "";
    return new Promise((resolve, reject) => {
      connectScalewayApi(
        node,
        input_text,
        (event: any) => {
          const data = JSON.parse(event.data);
          (updatedNode.data as EditionData).status = "processing" as NodeStatus;

          generation = generation + data.content;
          console.log("generation", generation);

          (updatedNode.data as EditionData).output = generation;

          const updatedNodes: CustomNode[] = currentAutomationTree?.nodes.map(
            (n) => (n.id === node.id ? updatedNode : n)
          ) as CustomNode[];

          let updatedReport = {
            ...(currentReport as Report),
            generation: generation,
          };
          // console.log("updatedReport.generation", updatedReport.generation);
          updateCurrentReport(updatedReport as Report); // Pourquoi mon updateCurent report ne marche pas ici ?

          updateCurrentAutomationTree({
            ...currentAutomationTree,
            nodes: updatedNodes,
          } as AutomationTree);

          return {};
        },
        (event: any) => {
          (updatedNode.data as EditionData).status = "success" as NodeStatus;
          const updatedNodes: CustomNode[] = currentAutomationTree?.nodes.map(
            (n) => (n.id === node.id ? updatedNode : n)
          ) as CustomNode[];
          updateIsLLMProcessing(false);
          updateCurrentAutomationTree({
            ...currentAutomationTree,
            nodes: updatedNodes,
          } as AutomationTree);
          resolve();
          return {};
        }
      );
    });
  };

  const processAnalyseNode = async (
    node: CustomNode,
    input_text: string | undefined
  ): Promise<void> => {
    if (!node.data || node.type !== "analyse") {
      throw new Error(
        "Le noeud doit être de type 'analyse' et contenir des données."
      );
    }

    // console.log("input_text", input_text);

    // Initialisation du noeud avec un output vide et un statut en cours
    node.data = {
      ...node.data,
      output: "",
      status: "pending",
    } as AnalyseData;

    const updatedNodes: CustomNode[] = currentAutomationTree?.nodes.map((n) =>
      n.id === node.id ? node : n
    ) as CustomNode[];

    updateCurrentAutomationTree({
      ...currentAutomationTree,
      nodes: updatedNodes,
    } as AutomationTree);

    const updatedNode = { ...node };

    // Mock de WebSocket
    console.log(`Connexion au WebSocket pour le noeud ${node.id}`);
    return new Promise((resolve, reject) => {
      connectScalewayApi(
        node,
        input_text,
        (event: any) => {
          const data = JSON.parse(event.data);
          (updatedNode.data as AnalyseData).status = "processing" as NodeStatus;
          console.log(
            "generation",
            (updatedNode.data as AnalyseData).output + data.content
          );

          (updatedNode.data as AnalyseData).output =
            (updatedNode.data as AnalyseData).output + data.content;

          const updatedNodes: CustomNode[] = currentAutomationTree?.nodes.map(
            (n) => (n.id === node.id ? updatedNode : n)
          ) as CustomNode[];

          updateCurrentAutomationTree({
            ...currentAutomationTree,
            nodes: updatedNodes,
          } as AutomationTree);

          return {};
        },
        (event: any) => {
          (updatedNode.data as AnalyseData).status = "success" as NodeStatus;
          const updatedNodes: CustomNode[] = currentAutomationTree?.nodes.map(
            (n) => (n.id === node.id ? updatedNode : n)
          ) as CustomNode[];

          updateCurrentAutomationTree({
            ...currentAutomationTree,
            nodes: updatedNodes,
          } as AutomationTree);
          resolve();
          return {};
        }
      );
    });
  };

  async function connectScalewayApi(
    node: CustomNode,
    input_text: string | undefined,
    callback: (event: any) => {},
    onClose: (event: any) => {}
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      console.log(
        "process.env.REACT_APP_SCALEWAY_URL",
        process.env.REACT_APP_SCALEWAY_URL
      );
      const client = new OpenAI({
        baseURL: process.env.REACT_APP_SCALEWAY_URL,
        apiKey: process.env.REACT_APP_SCALEWAY_API_KEY,
        dangerouslyAllowBrowser: true,
      });

      //Switch sur node.type
      switch (node.type) {
        case "analyse":
          fetch("/prompts/analyse_prompt.txt")
            .then((response) => response.text())
            .then(async (text) => {
              console.log("prommpt text", text);

              const contentToSend = text
                .replace("{input_text}", input_text as string)
                .replace(
                  "{instruction}",
                  (node.data as AnalyseData).instruction
                );
              console.log("contentToSend", contentToSend);

              const stream = await client.chat.completions.create({
                model: "llama-3.1-70b-instruct",
                messages: [
                  {
                    role: "user",
                    content: contentToSend,
                  },
                ],
                temperature: 0,
                top_p: 0.7,
                max_tokens: 2048,
                stream: true,
              });
              for await (const chunk of stream) {
                callback({
                  data: JSON.stringify({
                    content: chunk.choices[0]?.delta.content,
                  }),
                });
              }
              resolve();
              onClose({});
            })
            .catch((error) => {
              console.error("Error loading prompt:", error);
              reject();
              onClose({});
            });

          break;
        case "edition":
          fetch("/prompts/edition_prompt.txt")
            .then((response) => response.text())
            .then(async (text) => {
              console.log("prommpt text", text);

              const contentToSend = text
                .replace("{input_text}", input_text as string)
                .replace(
                  "{instruction}",
                  (node.data as EditionData).instruction
                )
                .replace(
                  "{generation}",
                  updatedGeneration.current?.replace(/<[^>]+>/g, "") as string
                );
              console.log("contentToSend", contentToSend);

              const response = await client.chat.completions.create({
                model: "llama-3.1-70b-instruct",
                messages: [
                  {
                    role: "system",
                    content: "Fais exactement ce que l'utilisateur te demande.",
                  },
                  {
                    role: "user",
                    content: contentToSend,
                  },
                ],
                temperature: 0,
                top_p: 0.7,
                max_tokens: 2048,
              });

              console.log("response", response.choices[0].message.content);

              fetch("/prompts/html_prompt.txt")
                .then((response) => response.text())
                .then(async (text) => {
                  const contentToSend = text
                    .replace(
                      "{raw_content}",
                      response.choices[0].message.content as string
                    )
                    .replace(
                      "{html_content}",
                      updatedGeneration.current as string
                    );
                  console.log("contentToSend", contentToSend);
                  const stream = await client.chat.completions.create({
                    model: "llama-3.1-8b-instruct",
                    messages: [
                      {
                        role: "system",
                        content:
                          "Fais exactement ce que l'utilisateur te demande.",
                      },
                      {
                        role: "user",
                        content: contentToSend,
                      },
                    ],
                    temperature: 0,
                    top_p: 0.7,
                    max_tokens: 2048,
                    stream: true,
                  });
                  for await (const chunk of stream) {
                    callback({
                      data: JSON.stringify({
                        content: chunk.choices[0]?.delta.content,
                      }),
                    });
                  }
                  resolve();
                  onClose({});
                })
                .catch((error) => {
                  console.error("Error loading prompt:", error);
                  reject();
                });
            })
            .catch((error) => {
              console.error("Error loading prompt:", error);
              reject();
            });

          break;
        default:
          break;
      }

      // console.log("chat response", stream);
    });
  }

  async function connectWebSocket(
    node: CustomNode,
    input_text: string | undefined,
    callback: (event: any) => {},
    onClose: (event: any) => {}
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const accessToken = localStorage.getItem("accessJWT");
      const refreshToken = localStorage.getItem("refreshJWT");
      // Créer une connexion WebSocket
      const ws = new WebSocket(
        "wss://" + process.env.REACT_APP_URL_BACKAPP_WS + "/ws/v1/automations",
        ["json", accessToken as string, refreshToken as string]
      );

      ws.addEventListener("message", (event) => {
        callback(event);
      });

      ws.addEventListener("error", (event) => {
        console.error("websocket error", event);
      });

      ws.addEventListener("close", (event) => {
        onClose(event);
        resolve();
      });

      ws.addEventListener("open", (event) => {
        if (node.type === "analyse")
          ws.send(
            JSON.stringify({
              input: input_text,
              instruction: (node.data as AnalyseData).instruction,
              action: "extract",
            })
          );
        else if (node.type === "edition")
          console.log("updatedGeneration.current", updatedGeneration.current);
        console.log(
          "updatedGeneration.current?.replace",
          updatedGeneration.current?.replace(/<[^>]+>/g, "")
        );
        ws.send(
          JSON.stringify({
            input: input_text,
            instruction: (node.data as EditionData).instruction,
            action: "edit",
            html_content: updatedGeneration.current,
            raw_content: updatedGeneration.current?.replace(/<[^>]+>/g, ""),
          })
        );
      });
    });
  }

  return { launchPipeline };
}
