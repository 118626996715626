import { Button, Flex, Switch, Tag, Typography } from "antd";
import {
  DesktopOutlined,
  PlusOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import useScreenSize from "../../../hooks/useScreensize";
import breakpoints from "../../../theme/antdesign";
import { useContext } from "react";
import Context from "../../../context/Context";
import useReports from "../../../hooks/useReports";
import useASR from "../../../hooks/useASR";
import LOGO_LIGHT from "../../../assets/THIANA_AI_LOGO_LIGHT.svg";
import LOGO_DARK from "../../../assets/THIANA_AI_LOGO_DARK.svg";

interface Props {
  SOCKET_ASR: React.MutableRefObject<WebSocket | undefined>;
}

export default function HeaderDocument(props: Props) {
  const { currentScreenSize } = useScreenSize();
  const { resetAppState } = useReports({});

  const { stopRecording } = useASR({
    SOCKET_ASR: props.SOCKET_ASR,
  });
  const {
    isMenuHidden,
    updateIsMenuHidden,
    isASRProcessing,
    teleconsultation,
    updateTeleconsultation,
    theme,
  } = useContext(Context);

  return (
    <Flex align="center" flex={1}>
      <Flex align="center" gap="small" flex={1}>
        <div>
          {isMenuHidden ? (
            <Flex align="center" gap={"small"}>
              <Button
                icon={<UnorderedListOutlined />}
                onClick={() => updateIsMenuHidden(false)}
              />
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  if (isASRProcessing) stopRecording();
                  resetAppState();
                }}
              >
                {currentScreenSize > breakpoints.md ? "Nouveau document" : null}
              </Button>
            </Flex>
          ) : null}
        </div>
      </Flex>

      <Flex align="center" justify="center" gap={8} flex={1}>
        <UserOutlined style={{ color: theme.iconColor }} />
        <Typography color={theme.textColor}>Consultation classique</Typography>
        <Switch value={teleconsultation} onChange={updateTeleconsultation} />
        <Flex gap={4}>
          <DesktopOutlined style={{ color: theme.iconColor }} />{" "}
          <Typography color={theme.textColor}>Téléconsultation</Typography>
        </Flex>
      </Flex>

      {currentScreenSize > breakpoints.md ? (
        <Flex align="center" justify="flex-end" gap={2} style={{ flex: 1 }}>
          {/* <Tag
            color="orange"
            // style={{
            //   margin: 0,
            //   fontSize: 8,
            //   paddingLeft: 4,
            //   paddingRight: 4,
            //   paddingTop: 3,
            //   paddingBottom: 3,
            //   lineHeight: 1,
            //   position: "fixed",
            //   right: 0,
            // }}
          >
            version bêta
          </Tag> */}
          <img
            alt="thiana_logo_assistant"
            style={{
              width: 128 + 64,
              height: "auto",
            }}
            src={theme.label === "light" ? LOGO_LIGHT : LOGO_DARK}
          />
        </Flex>
      ) : null}
    </Flex>
  );
}
