import { notification } from "antd";
import { errorWithCapitalization } from "../utils/errors";
import { BackendError } from "../types/BackendErrors";

/**
 * Ce middleware permet de récupérer (et stocker dans le localStorage) les nouveaux tokens si la requête revient en success
 * Si la reqûete revient en erreur 401, on déconnecte le user via la route /logout
 * @param response réponse de la requête
 * @returns
 */
const middleware = async (response: Response) => {
  // Success
  if (response.status >= 200 && response.status < 300) {
    let accessToken = response.headers.get("X-Access-Token");
    let refreshToken = response.headers.get("X-Refresh-Token");
    if (accessToken) localStorage.setItem("accessJWT", accessToken);
    if (refreshToken) localStorage.setItem("refreshJWT", refreshToken);
  }
  // Erreur
  else {
    // Si erreur 401 Unauthorized => logout
    if (response.status === 401) window.location.replace("/logout");
    // Sinon on affiche juste l'erreur renvoyé par le back
    let loginResponse = await response.json();
    loginResponse.errors?.forEach((error: BackendError) => {
      notification.error({
        message: "Erreur",
        description: errorWithCapitalization(error.message),
      });
    });
  }
  return response;
};

/* GET METHOD */
const get = async (url: string) => {
  const accessToken = localStorage.getItem("accessJWT");
  const refreshToken = localStorage.getItem("refreshJWT");
  return await middleware(
    await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-Refresh-Token": `${refreshToken}`,
      },
    })
  );
};

/* POST METHOD */
const post = async (url: string, data: any) => {
  const accessToken = localStorage.getItem("accessJWT");
  const refreshToken = localStorage.getItem("refreshJWT");
  return await middleware(
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "X-Refresh-Token": `${refreshToken}`,
      },
      body: JSON.stringify(data),
    })
  );
};

const put = async (url: string, data: any) => {
  const accessToken = localStorage.getItem("accessJWT");
  const refreshToken = localStorage.getItem("refreshJWT");
  return await middleware(
    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "X-Refresh-Token": `${refreshToken}`,
      },
      body: JSON.stringify(data),
    })
  );
};

/* GET METHOD */
const remove = async (url: string) => {
  const accessToken = localStorage.getItem("accessJWT");
  const refreshToken = localStorage.getItem("refreshJWT");
  return await middleware(
    await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-Refresh-Token": `${refreshToken}`,
      },
    })
  );
};

/***********************/
/* CUSTOMIZED REQUEST */
/***********************/
/* Authentification */
export const postAskNewTokenByScope = async (
  data: object,
  accessToken: string
) => {
  return await middleware(
    await fetch(process.env.REACT_APP_URL_BACKAPP + "/auth/scope", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    })
  );
};

export const getUserMe = async (accessToken: string) => {
  return await middleware(
    await fetch(process.env.REACT_APP_URL_BACKAPP + "/auth/me", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );
};

/* Back App Status */
export const getBackAppStatus = async () => {
  return await get(process.env.REACT_APP_URL_BACKAPP + "/health");
};
/* Reports */
export const postReport = async (data?: object) => {
  return await post(process.env.REACT_APP_URL_BACKAPP + "/reports", data);
};
export const putReport = async (id: string, data: object) => {
  return await put(process.env.REACT_APP_URL_BACKAPP + "/reports/" + id, data);
};
export const getReports = async (items: number, page?: number) => {
  return await get(
    process.env.REACT_APP_URL_BACKAPP +
      "/reports?items=" +
      items +
      (page ? "&page=" + page : "")
  );
};
export const deleteRecordById = async (id: string) => {
  return await remove(process.env.REACT_APP_URL_BACKAPP + "/reports/" + id);
};
/* Files */
export const getFileFromS3 = async (id: string) => {
  return await get(process.env.REACT_APP_URL_BACKAPP + "/storages/files/" + id);
};

/* Templates */
export const getTemplateById = async (id: string) => {
  return await get(
    process.env.REACT_APP_URL_BACKAPP + "/reports/templates/" + id
  );
};
export const postTemplate = async (data?: object) => {
  return await post(
    process.env.REACT_APP_URL_BACKAPP + "/reports/templates",
    data
  );
};
export const getTemplates = async (items: number, page?: number) => {
  return await get(
    process.env.REACT_APP_URL_BACKAPP +
      "/reports/templates?items=" +
      items +
      (page ? "&page=" + page : "")
  );
};
export const deleteTemplateById = async (id: string) => {
  return await remove(
    process.env.REACT_APP_URL_BACKAPP + "/reports/templates/" + id
  );
};

/* Feedbacks */
export const postFeedback = async (reportID: string, data?: object) => {
  return await post(
    process.env.REACT_APP_URL_BACKAPP + "/reports/" + reportID + "/feedbacks",
    data
  );
};

/* Transcriptions Recoveries */
export const getTranscriptionRecoveryByID = async (transcriptionID: string) => {
  return await get(
    process.env.REACT_APP_URL_BACKAPP +
      "/transcriptions/recoveries/" +
      transcriptionID
  );
};
