import React, { useContext } from "react";
import { Button, Flex } from "antd";
import FlowPannel from "../../../../common/react-flow/FlowPannel";
import {
  AnalyseData,
  ConditionData,
  CustomEdge,
  CustomNode,
  EditionData,
} from "../../../../../../types/ReactFlowCustomTypes";
import useFlowAutomation from "../../../../../../hooks/aiFlowAutomation/useFlowAutomation";
import Context from "../../../../../../context/Context";

export default function AIFlowAutomation() {
  const { launchPipeline } = useFlowAutomation();
  const { currentAutomationTree } = useContext(Context);

  return (
    <Flex
      vertical
      gap={4}
      style={{
        height: "calc(100vh - 131px)",
      }}
    >
      <Flex
        flex={1}
        style={{
          border: "1px solid rgba(0,0,0,0.1)",
          width: "100%",
          borderRadius: 8,
        }}
      >
        {currentAutomationTree?.nodes !== undefined &&
        currentAutomationTree?.edges !== undefined ? (
          <FlowPannel
            nodes={currentAutomationTree?.nodes as CustomNode[]}
            edges={currentAutomationTree?.edges as CustomEdge[]}
          />
        ) : (
          <></>
        )}
      </Flex>

      <Button
        type="primary"
        onClick={() =>
          launchPipeline(
            currentAutomationTree?.nodes as CustomNode[],
            currentAutomationTree?.edges as CustomEdge[]
          )
        }
      >
        Lancer la pipeline
      </Button>
    </Flex>
  );
}
