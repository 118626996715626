import { useContext, useRef, useState } from "react";
import Context from "../context/Context";
import {
  ErrorOutput,
  Report,
  Report as ThianaReport,
} from "@thiana/api-thiana-client";
import { errorWithCapitalization } from "../utils/errors";
import { BackendError } from "../types/BackendErrors";
import { notification } from "antd";
import useReports from "./useReports";

type WebsocketMessage = {
  generation: string;
};

export default function useLLM() {
  const isLLMProcessingRef = useRef(false);
  const socketRef = useRef<WebSocket>();
  const summaryHistory = useRef<string[]>(["", ""]);
  const [animatedLLMResponseState, setAnimatedLLMResponseState] = useState("");
  const [fixedLLMResponseState, setFixedLLMResponseState] = useState("");
  const globalSummaryRef = useRef<string>("");
  const generationRef = useRef<string>("");
  const {
    updateIsLLMProcessing,
    dispatchFlow,
    currentReport,
    updateCurrentReport,
  } = useContext(Context);

  let { updateReport, createSnapshot } = useReports({});

  const startGeneration = async (
    report: Report,
    command?: string,
    mode: "conversation" | "command" = "conversation",
    toBeCleaned: boolean = false
  ) => {
    const accessToken = localStorage.getItem("accessJWT");
    const refreshToken = localStorage.getItem("refreshJWT");
    updateIsLLMProcessing(true);
    if (currentReport && accessToken && refreshToken) {
      console.log("trimmed", report.generation.replace(/<[^>]+>/g, ""));
      launchWebsocket(
        "wss://" + process.env.REACT_APP_URL_BACKAPP_WS + "/ws/v1/generations",
        JSON.stringify({
          raw_content: report.generation.replace(/<[^>]+>/g, ""),
          html_content: report.generation,
          mode: mode,
          transcription:
            mode === "conversation" ? currentReport.transcription : command,
          report_id: report.id,
          toBeCleaned: toBeCleaned, // false ou true, clean à true => on réécrit le compte rendu sans les champs vides
          processingType: mode === "conversation" ? 1 : 2, // 1 pour conversation
        }),
        report
      );
    }
  };

  const launchWebsocket = async (
    url: string,
    payload: string,
    reportToUpdate?: Report
  ) => {
    const accessToken = localStorage.getItem("accessJWT") as string;
    const refreshToken = localStorage.getItem("refreshJWT") as string;
    try {
      // Créer une connexion WebSocket
      socketRef.current = new WebSocket(url, [
        "json",
        accessToken,
        refreshToken,
      ]);

      socketRef.current.addEventListener("open", (event: any) => {
        console.log("Connected to websocket.");
        socketRef.current?.send(payload);
      });

      socketRef.current.addEventListener("error", (event: any) => {
        stopGeneration();
      });

      socketRef.current.addEventListener("close", (event: any) => {
        if (currentReport && currentReport?.id !== "") {
          createSnapshot({
            ...currentReport,
            generation: generationRef.current,
          }); // On crée un snapshot en bdd du report actuel
          updateReport({ ...currentReport, generation: generationRef.current }); // On met à jour le report en bdd
        }
        stopGeneration();
      });

      socketRef.current.addEventListener(
        "message",
        function (event: MessageEvent<string>) {
          let data: any = JSON.parse(event.data);
          console.log(data);
          // Gestion des erreurs
          if (data.errors) {
            let errorOutput: ErrorOutput = JSON.parse(event.data);
            errorOutput.errors?.forEach((error: BackendError) => {
              notification.error({
                message: "Erreur lors de la génération",
                description: errorWithCapitalization(error.message),
              });
            });
          } else {
            let websocketMessage: WebsocketMessage = JSON.parse(event.data);

            // console.log("currentReport heeeere", currentReport);
            // On met à jour le currentReport affiché
            let updatedReport = {
              ...(reportToUpdate as Report),
              generation: generationRef.current + websocketMessage.generation,
            };

            generationRef.current =
              generationRef.current + websocketMessage.generation;

            console.log("updateCurrentReport", updateCurrentReport);
            updateCurrentReport(updatedReport as Report); // alors qu'il marche ici ?

            dispatchFlow({
              type: "SOCKET_LLM_FEEDBACK",
            });
            globalSummaryRef.current = websocketMessage.generation;
            summaryHistory.current.push(websocketMessage.generation);
            summaryHistory.current.shift();

            //set animated live response : slice du retour de la socket
            let animatedText = websocketMessage.generation.slice(
              summaryHistory.current[0].length,
              summaryHistory.current[1].length
            );
            //set fixed live response
            let fixedText = websocketMessage.generation.slice(
              0,
              summaryHistory.current[0].length
            );
            setAnimatedLLMResponseState(animatedText);
            setFixedLLMResponseState(fixedText);
          }
        }
      );
    } catch (error) {
      console.error(error);
      stopGeneration();
    }
  };

  const stopGeneration = async () => {
    generationRef.current = "";
    dispatchFlow({
      type: "SOCKET_LLM_STOP",
    });
    socketRef.current?.close();
    updateIsLLMProcessing(false);
  };

  return {
    startGeneration,
    stopGeneration,
    isLLMProcessingRef,
    fixedLLMResponseState,
    animatedLLMResponseState,
  };
}
