export type NodeStatus =
  | "initial"
  | "pending"
  | "processing"
  | "success"
  | "error";

export type EditionData = {
  name: string;
  input_type: "conversation" | "precedent_node";
  instruction: string;
  output: string;
  status: NodeStatus;
};

export type AnalyseData = {
  name: string;
  input_type: "conversation" | "precedent_node";
  instruction: string;
  output: string;
  status: NodeStatus;
};

export type ConditionData = {
  name: string;
  condition: string;
  answer: boolean | undefined;
  status: NodeStatus;
};

export type CustomNode = {
  id: string;
  type: "start" | "edition" | "condition" | "analyse" | "end";
  draggable: boolean;
  position: { x: number; y: number };
  data: EditionData | ConditionData | AnalyseData | undefined;
};

export type CustomEdge = {
  id: string;
  source: string;
  target: string;
  label: string;
};

export type AutomationTree = {
  id: string;
  nodes: CustomNode[];
  edges: CustomEdge[];
};

export const initialAutomationTree: AutomationTree = {
  id: "0000",
  nodes: [
    {
      id: "start",
      type: "start",
      draggable: false,
      position: { x: 0, y: 0 },
      data: undefined,
    },
    {
      id: "context",
      type: "analyse",
      draggable: false,
      position: { x: 0, y: 0 },
      data: {
        name: "Contexte",
        input_type: "conversation",
        instruction:
          "Rédige une phrase synthétique de 15 mots maximum reprendant le type de cancer du patient, son traitement et le stade de la prise en charge dans lequel il se trouve.",
        output: "",
        status: "initial",
      } as AnalyseData,
    },
    {
      id: "edit-context",
      type: "edition",
      draggable: false,
      position: { x: 0, y: 0 },
      data: {
        name: "Edition contexte",
        input_type: "precedent_node",
        instruction:
          "Remplace '[contexte]' par le texte qui t'es donné. Laisse le reste du texte inchangé.",
        output: "",
        status: "initial",
      } as EditionData,
    },
    {
      id: "anamnese",
      type: "analyse",
      draggable: false,
      position: { x: 0, y: 0 },
      data: {
        name: "Anamnèse",
        input_type: "conversation",
        instruction:
          "Liste les symptomes qu'a présenté la patiente depuis la dernière consultation. Cherche en particulier si le/la patient(e) a eu des bouffées de chaleur et des douleurs articulaires. S'il n'en a pas eu, précise 'Pas de bouffées de chaleur. Pas d'arthralgies'.",
        output: "",
        status: "initial",
      } as AnalyseData,
    },
    {
      id: "edit-anamnese",
      type: "edition",
      draggable: false,
      position: { x: 0, y: 0 },
      data: {
        name: "Edition Anamnèse",
        input_type: "precedent_node",
        instruction:
          "Remplace '[anamnese]' par le texte qui t'es donné. Laisse le reste du texte inchangé.",
        output: "",
        status: "initial",
      } as EditionData,
    },
    {
      id: "examen-clinique",
      type: "analyse",
      draggable: false,
      position: { x: 0, y: 0 },
      data: {
        name: "Examen clinique",
        input_type: "conversation",
        instruction: `Voici un examen clinique normal. Adapte le en fonction des éléments présents dans la consultation. Ta réponse aura la forme suivante :\n\n
        'Examen clinique :\n
        Cicatrice de [reconstruction/mastectomie/tumorectomie] saine. Pas de nodule palpable.\n
        Sein controlatéral sans anomalie.\n
        Pas d'adénopathie palpable.\n
        Reste de l'examen clinique sans particularité.'
        `,
        output: "",
        status: "initial",
      } as EditionData,
    },
    {
      id: "edit-examen-clinique",
      type: "edition",
      draggable: false,
      position: { x: 0, y: 0 },
      data: {
        name: "Edition examen clinique",
        input_type: "precedent_node",
        instruction:
          "Remplace '[examen clinique]' par le texte qui t'es donné. Laisse le reste du texte inchangé.",
        output: "",
        status: "initial",
      } as EditionData,
    },
    {
      id: "examens-complementaires",
      type: "analyse",
      draggable: false,
      position: { x: 0, y: 0 },
      data: {
        name: "Examens complementaires",
        input_type: "conversation",
        instruction: `Liste les examens complémentaires pratiqués et leurs éventuels résultats.`,
        output: "",
        status: "initial",
      } as EditionData,
    },
    {
      id: "edit-examens-complementaires",
      type: "edition",
      draggable: false,
      position: { x: 0, y: 0 },
      data: {
        name: "Edition examens complémentaires",
        input_type: "precedent_node",
        instruction:
          "Remplace '[examens complementaires]' par le texte qui t'es donné. Laisse le reste du texte inchangé.",
        output: "",
        status: "initial",
      } as EditionData,
    },
    {
      id: "synthese",
      type: "analyse",
      draggable: false,
      position: { x: 0, y: 0 },
      data: {
        name: "Synthèse",
        input_type: "conversation",
        instruction:
          "Rédige une phrase de synthèse sur l'évolution du patient depuis la dernière fois, en précisant le cycle de traitement.",
        output: "",
        status: "initial",
      } as EditionData,
    },
    {
      id: "edit-synthese",
      type: "edition",
      draggable: false,
      position: { x: 0, y: 0 },
      data: {
        name: "Edition synthèse",
        input_type: "precedent_node",
        instruction:
          "Remplace '[synthese]' par le texte qui t'es donné. Laisse le reste du texte inchangé.",
        output: "",
        status: "initial",
      } as EditionData,
    },
    {
      id: "conduite-a-tenir",
      type: "analyse",
      draggable: false,
      position: { x: 0, y: 0 },
      data: {
        name: "Conduite à tenir",
        input_type: "conversation",
        instruction: `Voici un texte de 'au total'. Adapte le en fonction des éléments présents dans la consultation. Ta réponse aura la forme suivante :\n\n
        'Rémission complète clinique et radiologique.\n
        Poursuite de la surveillance et de l'Anastrazole que l'on renouvelle ce jour.\n
        Patiente à revoir dans 1 an avec une nouvelle mammographie.\n'`,
        output: "",
        status: "initial",
      } as EditionData,
    },
    {
      id: "edit-conduite-a-tenir",
      type: "edition",
      draggable: false,
      position: { x: 0, y: 0 },
      data: {
        name: "Edition conduite à tenir",
        input_type: "precedent_node",
        instruction:
          "Remplace '[prise en charge]' par le texte qui t'es donné. Laisse le reste du texte inchangé.",
        output: "",
        status: "initial",
      } as EditionData,
    },
    {
      id: "end",
      type: "end",
      draggable: false,
      position: { x: 0, y: 0 },
      data: undefined,
    },
  ],
  edges: [
    {
      id: "0",
      source: "start",
      target: "context",
      label: "",
    },
    {
      id: "1bis",
      source: "context",
      target: "edit-context",
      label: "",
    },
    {
      id: "1ter",
      source: "edit-context",
      target: "anamnese",
      label: "",
    },
    {
      id: "1",
      source: "anamnese",
      target: "edit-anamnese",
      label: "",
    },
    {
      id: "2",
      source: "edit-anamnese",
      target: "examen-clinique",
      label: "",
    },
    {
      id: "3",
      source: "examen-clinique",
      target: "edit-examen-clinique",
      label: "",
    },
    {
      id: "4",
      source: "edit-examen-clinique",
      target: "examens-complementaires",
      label: "",
    },
    {
      id: "5",
      source: "examens-complementaires",
      target: "edit-examens-complementaires",
      label: "",
    },
    {
      id: "6",
      source: "edit-examens-complementaires",
      target: "synthese",
      label: "",
    },
    {
      id: "7",
      source: "synthese",
      target: "edit-synthese",
      label: "",
    },
    {
      id: "8",
      source: "edit-synthese",
      target: "conduite-a-tenir",
      label: "",
    },
    {
      id: "7bis",
      source: "conduite-a-tenir",
      target: "edit-conduite-a-tenir",
      label: "",
    },
    {
      id: "8bis",
      source: "edit-conduite-a-tenir",
      target: "end",
      label: "",
    },
  ],
};
