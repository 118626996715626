import {
  faGears,
  faQuestion,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Flex, Tag, Typography } from "antd";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  NodeToolbar,
  useNodesState,
  useEdgesState,
  addEdge,
  Handle,
  Position,
} from "reactflow";
import Context from "../../../../../context/Context";
import { AnalyseData } from "../../../../../types/ReactFlowCustomTypes";

interface AnalyseNodeProps {
  data: AnalyseData;
}

export default function AnalyseNode({ data }: AnalyseNodeProps) {
  const { theme } = useContext(Context);
  const onChange = useCallback((evt: any) => {
    console.log(evt.target.value);
  }, []);

  return (
    <Flex
      vertical
      gap={4}
      style={{
        width: 500,
        maxHeight: 300,
        border: "1px solid rgba(0,0,0,0.2)",
        borderRadius: 8,
        boxShadow:
          "0px 4px 8px -2px rgba(24, 24, 28, 0.10), 0px 2px 4px -2px rgba(24, 24, 28, 0.06)",
      }}
    >
      <Handle position={Position.Top} type="target" />
      <Flex
        style={{
          color: "#005BB8",
          fontSize: 22,
          borderBottom: "1px solid rgba(0,0,0,0.2)",
          padding: "8px 16px",
        }}
        justify="space-between"
      >
        <b>
          <FontAwesomeIcon icon={faSearch} /> Analyse
        </b>
        <Tag color="volcano">{data.status}</Tag>
      </Flex>
      <Flex
        flex={1}
        style={{
          padding: "8px 16px 16px 16px",
        }}
      >
        <Flex>
          <b>Nom :</b> {data.name}
        </Flex>
      </Flex>
      <Flex
        flex={1}
        style={{
          padding: "8px 16px 16px 16px",
        }}
      >
        <Flex>
          <b>Instruction :</b> {data.instruction}
        </Flex>
      </Flex>
      <Flex
        flex={1}
        style={{
          padding: "8px 16px 16px 16px",
        }}
      >
        <Flex style={{ overflowY: "scroll" }}>
          <b>Génération :</b> {data.output}
        </Flex>
      </Flex>
      <Handle position={Position.Bottom} type="source" />
    </Flex>
  );
}
