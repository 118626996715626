import { ThemeConfig, theme } from "antd";
import { ThianaAssistantMode } from "../types/Reports";
import { ThianaAssistantTheme } from "../types/Theme";

export default function useDarkTheme(mode: ThianaAssistantMode) {
  const { darkAlgorithm } = theme;

  const darkTheme: ThemeConfig | undefined = {
    algorithm: darkAlgorithm,
    token: {
      colorPrimary: "#1677FF",
    },
    components: {
      Popover: {
        colorBorder: "red",
      },
    },
  };

  const specificDarkThemeColors: ThianaAssistantTheme = {
    colorStatement: "#1677FF",
    colorConversation: "#1677FF",
    label: "dark",
    sideBackgroundColor: "#141414",
    contentBackgroundColor: "#1f1f1f",
    iconColor: "white",
    textColor: "white",
  };

  return { darkTheme, specificDarkThemeColors };
}
