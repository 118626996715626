import {
  faGears,
  faQuestion,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Flex, Tag, Typography } from "antd";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  NodeToolbar,
  useNodesState,
  useEdgesState,
  addEdge,
  Handle,
  Position,
} from "reactflow";
import Context from "../../../../../context/Context";
import { AnalyseData } from "../../../../../types/ReactFlowCustomTypes";

export default function StartNode() {
  return (
    <Flex
      vertical
      gap={4}
      style={{
        width: 500,
        border: "1px solid rgba(0,0,0,0.2)",
        borderRadius: 8,
        boxShadow:
          "0px 4px 8px -2px rgba(24, 24, 28, 0.10), 0px 2px 4px -2px rgba(24, 24, 28, 0.06)",
      }}
    >
      <Typography.Title>DEBUT</Typography.Title>
      <Handle position={Position.Bottom} type="source" />
    </Flex>
  );
}
