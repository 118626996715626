import { Button, Flex, Typography } from "antd";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  NodeToolbar,
  useNodesState,
  useEdgesState,
  addEdge,
  Handle,
  Position,
  useOnViewportChange,
} from "reactflow";
import ConditionNode from "./custom-nodes/ConditionNode";
import EditionNode from "./custom-nodes/EditionNode";
import "reactflow/dist/style.css";
import Context from "../../../../context/Context";
import dagre from "@dagrejs/dagre";
import AnalyseNode from "./custom-nodes/AnalyseNode";
import EndNode from "./custom-nodes/EndNode";
import StartNode from "./custom-nodes/StartNode";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 500;
const nodeHeight = 150;

const getLayoutedElements = (nodes: any, edges: any, direction = "TB") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({
    rankdir: direction,
    ranksep: 500, // Espacement vertical entre les cartes
    nodesep: 50,
  });

  nodes.forEach((node: any) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge: any) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node: any) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? Position.Left : Position.Top;
    node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

interface Props {
  nodes: any[];
  edges: any[];
}

export default function FlowPannel(props: Props) {
  const [nodes, setNodes, onNodesChange] = useNodesState(props.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(props.edges);
  const [isDragging, setIsDragging] = useState(false);

  const nodeTypes = useMemo(
    () => ({
      condition: ConditionNode,
      edition: EditionNode,
      analyse: AnalyseNode,
      start: StartNode,
      end: EndNode,
    }),
    []
  );

  const { theme } = useContext(Context);

  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  useEffect(() => {
    setEdges(props.edges);
  }, [props.edges]);

  useEffect(() => {
    setNodes(props.nodes);
  }, [props.nodes]);

  useEffect(() => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      nodes,
      edges
    );
    setNodes([...layoutedNodes]);
    setEdges([...layoutedEdges]);
  }, [nodes, edges]);

  useOnViewportChange({
    onStart: () => setIsDragging(true),
    onEnd: () => setIsDragging(false),
  });

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      fitView
      zoomOnScroll={false}
      panOnScroll={false}
      zoomOnDoubleClick={false}
      panOnDrag={true}
      proOptions={{ hideAttribution: true }}
    >
      <Controls />
      {isDragging && <MiniMap />}
      <Background gap={12} size={1} />
      <NodeToolbar isVisible={true}>
        <Button>Test</Button>
      </NodeToolbar>
    </ReactFlow>
  );
}
