import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Flex, Tag, Typography } from "antd";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  NodeToolbar,
  useNodesState,
  useEdgesState,
  addEdge,
  Handle,
  Position,
} from "reactflow";
import Context from "../../../../../context/Context";
import { ConditionData } from "../../../../../types/ReactFlowCustomTypes";

interface ConditionNodeProps {
  data: ConditionData;
}

export default function ConditionNode({ data }: ConditionNodeProps) {
  const { theme } = useContext(Context);
  // const onChange = useCallback((evt: any) => {
  //   console.log(evt.target.value);
  // }, []);

  return (
    <Flex
      vertical
      gap={4}
      style={{
        width: 500,
        border: "1px solid rgba(0,0,0,0.2)",
        borderRadius: 8,
        boxShadow:
          "0px 4px 8px -2px rgba(24, 24, 28, 0.10), 0px 2px 4px -2px rgba(24, 24, 28, 0.06)",
      }}
    >
      <Handle position={Position.Top} type="target" />
      <Flex
        style={{
          color: "#7F3BF3",
          fontSize: 22,
          borderBottom: "1px solid rgba(0,0,0,0.2)",
          padding: "8px 16px",
        }}
        justify="space-between"
      >
        <b>
          <FontAwesomeIcon icon={faQuestion} /> Condition
        </b>
        <Tag color="volcano">{data.status}</Tag>
      </Flex>
      <Flex vertical gap={8} style={{ padding: "8px 16px 16px 16px" }}>
        <div>
          <b>Condition :</b> {data.condition}
        </div>
        <Flex gap={4}>
          <b>Réponse : </b>
          {data.answer === undefined ? (
            <Tag>Aucune</Tag>
          ) : data.answer === true ? (
            <Tag color="success">Oui</Tag>
          ) : (
            <Tag color="error">Non</Tag>
          )}
        </Flex>
      </Flex>
      <Handle position={Position.Bottom} type="source" />
    </Flex>
  );
}
