import { createContext } from "react";
import { initialState, Action, State } from "../reducer/flowReducer";
import {
  Professional,
  Report as ThianaReport,
  Subscription,
  User,
} from "@thiana/api-thiana-client";
import { emptyReport, ThianaAssistantMode } from "../types/Reports";
import { ThianaAssistantTheme } from "../types/Theme";
import { Template } from "../types/Templates";
import { AutomationTree } from "../types/ReactFlowCustomTypes";

// import { ConsultationMode } from "../types/ConsultationMode";

// Define context type
type Context = {
  user?: User;
  updateUser: (arg?: User) => void;

  professional?: Professional;
  updateProfessional: (arg?: Professional) => void;

  subscription?: Subscription;
  updateSubscription: (arg?: Subscription) => void;

  fileAvatar?: string;
  updateFileAvatar: (arg: string) => void;

  currentReport?: ThianaReport;
  updateCurrentReport: (arg: ThianaReport | undefined) => void;

  currentTemplate?: Template;
  updateCurrentTemplate: (arg: Template) => void;

  reports: ThianaReport[];
  updateReports: (arg: ThianaReport[]) => void;

  currentConversation?: string;
  updateCurrentTranscription: (arg: string) => void;

  currentCommand: string;
  updateCurrentCommand: (arg: string) => void;

  currentContent: string;
  updateCurrentContent: (arg: string) => void;

  isASRProcessing: boolean;
  updateIsASRProcessing: (arg: boolean) => void;

  isLLMProcessing: boolean;
  updateIsLLMProcessing: (arg: boolean) => void;

  extractionStep: string;
  updateExtractionStep: (arg: string) => void;

  isMenuHidden: boolean | undefined;
  updateIsMenuHidden: (arg: boolean) => void;

  currentTab: string;
  updateCurrentTab: (arg: string) => void;

  flowState: State;
  dispatchFlow: (arg: Action) => void;

  isSaved: SaveReport;
  updateIsSaved: (arg: SaveReport) => void;

  isTemplateSaved: boolean;
  updateIsTemplateSaved: (arg: boolean) => void;

  mode?: ThianaAssistantMode;
  updateMode: (arg: ThianaAssistantMode) => void;

  teleconsultation: boolean;
  updateTeleconsultation: (arg: boolean) => void;

  isMicrophoneAvailable: boolean;
  updateIsMicrophoneAvailable: (arg: boolean) => void;

  stepsRef: React.MutableRefObject<null>[];
  updateStepsRef: (arg: React.MutableRefObject<null>[]) => void;

  openTour: boolean;
  updateOpenTour: (arg: boolean) => void;

  theme: ThianaAssistantTheme;
  updateTheme: (arg: ThianaAssistantTheme) => void;

  toBeCleaned: boolean;
  updateToBeCleaned: (arg: boolean) => void;

  currentAutomationTree: AutomationTree | undefined;
  updateCurrentAutomationTree: (arg: AutomationTree) => void;
};

export type SaveReport = {
  isSaved: boolean;
  updatedField?: "generation" | "title" | "transcription" | "template";
};

// Context
export default createContext<Context>({
  user: undefined,
  updateUser: (arg?: User) => {},

  professional: undefined,
  updateProfessional: (arg?: Professional) => {},

  currentReport: emptyReport,
  updateCurrentReport: (arg: ThianaReport | undefined) => {},

  currentTemplate: undefined,
  updateCurrentTemplate: (arg: Template) => {},

  reports: [],
  updateReports: (arg: ThianaReport[]) => {},

  subscription: undefined,
  updateSubscription: (arg?: Subscription) => {},

  fileAvatar: undefined,
  updateFileAvatar: (arg: string) => {},

  currentConversation: undefined,
  updateCurrentTranscription: (arg: string) => {},

  currentCommand: "",
  updateCurrentCommand: (arg: string) => {},

  currentContent: "",
  updateCurrentContent: (arg: string) => {},

  isASRProcessing: false,
  updateIsASRProcessing: (arg: boolean) => {},

  isLLMProcessing: false,
  updateIsLLMProcessing: (arg: boolean) => {},

  extractionStep: "",
  updateExtractionStep: (arg: string) => {},

  isMenuHidden: true,
  updateIsMenuHidden: (arg: boolean) => {},

  currentTab: "",
  updateCurrentTab: (arg: string) => {},

  flowState: initialState,
  dispatchFlow: (arg: Action) => {},

  isSaved: { isSaved: true },
  updateIsSaved: (arg: SaveReport) => {},

  isTemplateSaved: false,
  updateIsTemplateSaved: (arg: boolean) => {},

  mode: "statement",
  updateMode: (arg: ThianaAssistantMode) => {},

  teleconsultation: false,
  updateTeleconsultation: (arg: boolean) => {},

  isMicrophoneAvailable: false,
  updateIsMicrophoneAvailable: (arg: boolean) => {},

  stepsRef: [],
  updateStepsRef: (arg: React.MutableRefObject<null>[]) => {},

  openTour: false,
  updateOpenTour: (arg: boolean) => {},

  toBeCleaned: true,
  updateToBeCleaned: (arg: boolean) => {},

  theme: {
    colorStatement: "",
    colorConversation: "",
    sideBackgroundColor: "",
    contentBackgroundColor: "",
    label: "light",
    iconColor: "black",
    textColor: "black",
  },
  updateTheme: (arg: ThianaAssistantTheme) => {},

  currentAutomationTree: undefined,
  updateCurrentAutomationTree: (arg: AutomationTree) => {},
});
