import { ThemeConfig, theme } from "antd";
import { ThianaAssistantMode } from "../types/Reports";
import { ThianaAssistantTheme } from "../types/Theme";

export default function useLightTheme(mode: ThianaAssistantMode) {
  const { defaultAlgorithm } = theme;

  const lightTheme: ThemeConfig | undefined = {
    algorithm: defaultAlgorithm,
    token: {
      colorPrimary: "#1677FF",
    },
  };

  const specificLightThemeColors: ThianaAssistantTheme = {
    colorStatement: "#1677FF",
    colorConversation: "#1677FF",
    label: "light",
    sideBackgroundColor: "whitesmoke",
    contentBackgroundColor: "white",
    iconColor: "black",
    textColor: "black",
  };

  return { lightTheme, specificLightThemeColors };
}
